import React from "react"
import { PageProps, Link, graphql } from "gatsby"
import Page from "../components/common/page"
import Card from "../components/Card"
import HeroCard from "../components/HeroCard"
import HeroSection from "../components/HeroSection"
import ContactUsSection from "../components/ContactUsSection"
import HeroIcon from "../images/svg/why-video-learning/hero-icon.inline.svg"
import HeroIconBg from "../images/svg/why-video-learning/hero-bg.inline.svg"
import CardItemIcon1 from "../images/svg/why-video-learning/card-items/card-icon-item-1.inline.svg"
import CardItemIcon2 from "../images/svg/why-video-learning/card-items/card-icon-item-2.inline.svg"
import CardItemIcon3 from "../images/svg/why-video-learning/card-items/card-icon-item-3.inline.svg"
import CardItemIcon4 from "../images/svg/why-video-learning/card-items/card-icon-item-4.inline.svg"
import CardItemIcon5 from "../images/svg/why-video-learning/card-items/card-icon-item-5.inline.svg"
import CardItemIcon6 from "../images/svg/why-video-learning/card-items/card-icon-item-6.inline.svg"
import Header from "../components/header"
import Footer from "../components/footer"
import { Trans, useTranslation } from "react-i18next"

const WhyVideoLearningPage = (props: PageProps) => {
  const { t } = useTranslation()
  return (
    <Page title="Video Learning">
      <Header />
      <div className="w-full overflow-x-hidden">
        <div className="relative">
          <div className="relative z-20">
            <HeroSection
              title={
                <p className="font-bold lg:text-6xl text-4xl text-secondary gothic-bold relative">
                  {t("why_video_learning_question")}
                </p>
              }
              icon={<HeroIcon />}
            ></HeroSection>
          </div>
          <HeroIconBg className="bg-background w-full absolute -bottom-12 lg:-bottom-12 z-0" />
        </div>
        <div className="mt-12 bg-background flex flex-col text-center items-center z-20">
          <div className="lg:mx-80 m-5 w-full">
            <div
              data-sal="slide-down"
              className="lg:mt-24 mt-2 lg:text-5xl text-2xl w-full"
            >
              <p className="gothic-bold text-dark-grey mb-3">
                {t("why_to_replace_traditional_education")}
              </p>
              <p className="text-secondary gothic-bold">
                {" "}
                {t("with_video_learning")}
              </p>
              <p className="lg:text-lg text-sm mt-6">{t("lot_of_reasons")}</p>
            </div>

            <div className={`flex flex-row flex-wrap mt-12 justify-center`}>
              {cardItems.map((item, i) => (
                <Card
                  key={i}
                  rootClassName="p-8 m-2 lg:w-3/12 w-full text-left bg-white rounded-3xl space-y-4"
                  titleClassName="text-3xl my-4 text-left block"
                  subtitleClassName="mt-2 block"
                  title={item.title}
                  subtitle={t(item.subtitle)}
                  icon={item.icon}
                  slideAnimation="zoom-in"
                ></Card>
              ))}
            </div>
            <HeroCard
              slideAnimation="slide-left"
              rootClassName="justify-center text-left rounded-3xl lg:px-52 px-5 lg:mx-primary my-20"
            >
              <div>
                <p className="lg:text-5xl text-2xl gothic-bold text-dark-grey flex flex-col">
                  {t("why_to_trust_education_in_video")}{" "}
                  <div className="text-secondary">{t("of_my_company")}</div>
                </p>

                <div className="mt-6 lg:text-base text-sm">
                  <p>{t("why_trust_text_1")} </p>
                  <p className="mt-6">
                    {t("why_trust_text_2")}{" "}
                  </p>
                  <p className="mt-6">
                    {t("why_trust_text_3")}
                  </p>
                </div>
              </div>
            </HeroCard>
            <HeroCard
              slideAnimation="slide-right"
              rootClassName="bg-white text-left rounded-3xl lg:px-52 px-5 lg:mx-primary py-20"
            >
              <div>
                <div className="lg:text-5xl text-2xl font-bold gothic-bold text-dark-grey leading-relaxed">
                  <p>{t("why_on_demand_video")}</p>
                  <p className="text-secondary">
                  {t("why_microlearning")}
                  </p>
                </div>

                <div className="lg:text-base text-sm">
                  <p className="mt-6">
                    {t('why_on_demand_video_text_1')}{" "}
                  </p>
                  <p className="mt-6">
                  {t('why_on_demand_video_text_2')}{" "}
                  </p>
                  <p className="mt-6">
                  {t('why_on_demand_video_text_3')}
                  </p>
                  <p className="mt-6">
                  {t('why_on_demand_video_text_4')}
                  </p>
                  <p className="mt-6">
                  {t('why_on_demand_video_text_5')}
                  </p>
                </div>
              </div>
            </HeroCard>
          </div>
          <ContactUsSection />
        </div>
      </div>
      <Footer />
    </Page>
  )
}

const cardItems = [
  {
    icon: <CardItemIcon1 />,
    title: (
      <p className="font-bold text-card leading-10">
        <span className="block font-bold">
          <Trans>education</Trans>
        </span>
        <span className="text-dark-grey gothic-bold">
          <Trans>is_consistent</Trans>
        </span>
      </p>
    ),
    subtitle: "education_consistent_text",
  },
  {
    icon: <CardItemIcon2 />,
    title: (
      <p className="font-bold text-card leading-10">
        <span className="block font-bold">
          <Trans>video_learning</Trans>
        </span>
        <span className="text-dark-grey gothic-bold">
          <Trans>lower_cost</Trans>
        </span>
      </p>
    ),
    subtitle: "lower_cost_text",
  },
  {
    icon: <CardItemIcon3 />,
    title: (
      <p className="font-bold text-card leading-10">
        <span className="block font-bold">
          <Trans>education</Trans>
        </span>
        <span className="text-dark-grey gothic-bold">
          <Trans>is_flexible</Trans>
        </span>
      </p>
    ),
    subtitle: "is_flexible_text",
  },
  {
    icon: <CardItemIcon4 />,
    title: (
      <p className="font-bold text-card leading-10">
        <span className="block font-bold">
          <Trans>education</Trans>
        </span>
        <span className="text-dark-grey gothic-bold">
          <Trans>is_engaging</Trans>
        </span>
      </p>
    ),
    subtitle: "is_engaging_text",
  },
  {
    icon: <CardItemIcon5 />,
    title: (
      <p className="font-bold text-card leading-10">
        <span className="block font-bold">
          <Trans>education</Trans>
        </span>
        <span className="text-dark-grey gothic-bold">
          <Trans>is_faster</Trans>
        </span>
      </p>
    ),
    subtitle: "is_faster_text",
  },
  {
    icon: <CardItemIcon6 />,
    title: (
      <p className="font-bold text-card leading-10">
        <span className="block font-bold">
          <Trans>results</Trans>
        </span>
        <span className="text-dark-grey gothic-bold">
          <Trans>are_countable</Trans>
        </span>
      </p>
    ),
    subtitle: "countable_results_text",
  },
]

export default WhyVideoLearningPage
export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["why-video-learning", "common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
